import styled from 'styled-components'
import {
  primaryColor,
  bodyFont,
  themeColor,
  white,
  xl,
  lg,
  md,
} from 'config/variables'
import { images } from 'config/images'
export const KeybenefitssecStyles = styled.div`
  margin-bottom: 126px;
  ${xl(`
        margin-bottom: 100px;
    `)} 
  ${lg(`
        margin-bottom: 90px;
    `)} 
  ${md(`
        margin-bottom: 40px;
    `)} 
  .KeybenefitsSec {
    width: 100%;
    margin-top: 20px;
    position: relative;
    padding: 20px 0;
    .KeybenefitsSec-bg {
      width: calc(100% - 40px);
      height: 100%;
      left: 20px;
      top: 0;
      position: absolute;
      background: transparent;
      opacity: 0.3;
      z-index: -1;
      background: url(${images.benefitssecBg}); 
    }
    .KeybenefitsSec-blk {
      box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.06),
        0 3px 5px -1px rgba(0, 0, 0, 0.1);
      border: solid 1px #e3c369;
      background-color: #fedc7a;
      width: 100%;
      display: flex;
      padding: 40px 60px 37px 35px;
      align-items: flex-start;

      ${lg(`
            padding: 35px 20px 36px 20px;
        `)} 
         ${md(`
            flex-direction: column;
            padding: 30px 24px;
        `)} 
      .KeybenefitsThumb {
        flex: 0 0 242px;
        ${lg(`
            flex: 0 0 205px;
            margin-bottom:30px;
        `)} 
         ${md(`
            flex: 0 0 auto;
            width:100%;
            max-width:128px;
            align-self:center;
        `)} 
        figure {
          margin: 0px;
        }
      }
      .KeybenefitsDetails {
        flex: 1 1 auto;
        padding-left: 77px;
        ${xl(`
              padding-left: 42px;
          `)} 
           ${lg(`
              padding-left: 42px;
          `)} 
          ${md(`
              padding-left:0px;
          `)} 
        h4 {
          font-size: 38px;
          margin-bottom: 21px;
          font-weight: 500;
          line-height: 48px;
          color: ${primaryColor};
          letter-spacing:-0.41px;
          ${lg(`
             font-size: 35px;
             line-height: 44px;
             letter-spacing:-0.38px;
          `)} 
          ${md(`
             font-size: 24px;
             line-height: 28px;
          `)} 
        }
        .benefitsList {
          padding-bottom: 46px;
          ${md(`
              padding-bottom: 26px;
            `)} 
          li {
            padding-left: 38px;
            list-style: none;
            position: relative;
            font-family: ${bodyFont};
            font-size: 20px;
            font-weight: 500;
            line-height: 27px;
            margin-bottom: 15px;
            ${md(`
              font-size: 16px;
              line-height: 20px;
              padding-left: 32px;
            `)} 
            &:last-child {
              margin-bottom: 0px;
            }
            &:after {
              content: '';
              position: absolute;
              width: 22px;
              height: 23px;
              background: url(${images.checkLineIcons});
              top: 5px;
              left: 0;
              ${md(`
                width: 18px;
                height: 18px;
                background-size:cover;
              `)} 
            }
          }
        }
        .button {
          background: ${themeColor};
          color: ${white};
          font-size: 14px;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: 1.4px;
          text-transform: uppercase;
          padding: 9px 22px 11px;
          ${md(`
              font-size: 12px;
              line-height: 18px;
              padding: 9px 11px 11px;
            `)} 
          &:before,
          &:after {
            background: ${white};
          }
          &:hover {
            color: ${themeColor};
          }
        }
      }
    }
  }
`
