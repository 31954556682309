import React from 'react'
import { TreemappingfullimgsecStyles } from './Treemappingfullimgsec.styles'
import Image from 'components/ImgOptimized'

function Treemappingfullimgsec(props) {
  const {
    treemappingFullImgData: { treemappingFullImgProps },
    showFullimages,
  } = props
  const {
    data: {
      treemappingthumb: {
        childImageSharp: { fluid: treemappingthumb },
      },
    },
  } = props
  return (
    <TreemappingfullimgsecStyles>
      <div className="container">
        <div className="businessHeading">
          <h2>{treemappingFullImgProps.title}</h2>
        </div>
        <div className="engagementContent">
          <div className="TreemappingContent">
            <div className="left-part"></div>
            <div className="right-part">
              <p>{treemappingFullImgProps.maincontent}</p>
            </div>
          </div>
          <div className="engagementGraphImage">
            {showFullimages && (
              <figure>
                <Image
                  fluid={treemappingthumb}
                  lazyload={{
                    enabled: true,
                    once: true,
                    offset: 1000,
                    heigth: 410,
                  }}
                />
              </figure>
            )}
          </div>
          <div className="bottomTreemappingContent">
            <div className="left-part">
              {treemappingFullImgProps.bottomTitle ? (
                <div className="bottom-content">
                  <h4>{treemappingFullImgProps.bottomTitle}</h4>
                  <p>{treemappingFullImgProps.bottomContent}</p>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="right-part">
              <p>{treemappingFullImgProps.bottompara}</p>
            </div>
          </div>
        </div>
      </div>
    </TreemappingfullimgsecStyles>
  )
}

export default Treemappingfullimgsec
