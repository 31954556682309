import styled from 'styled-components'
import { primaryColor, xl, lg, md } from 'config/variables'

export const ProcessinginformationsecStyles = styled.div`
   margin-top:130px;
  ${xl(`
      margin-top:100px;
  `)} 
  ${lg(`
      margin-top:70px;
  `)} 
   ${md(`
      margin-top:40px;
  `)} 
 
  .mobileAppsContent {
    display: flex;
    justify-content: space-between;
    margin-bottom:50px;
    ${md(`
       flex-direction: column;
       margin-bottom:10px;
    `)}  
    .mobileAppsContentLeft {
      flex: 0 1 479px;
      padding-right: 25px;
      ${xl(`
           padding-right: 20px;
           flex: 0 1 500px;
      `)} 
      ${lg(`
           padding-right: 15px;
           flex: 0 1 400px;
      `)} 
      ${md(`
        flex:1 1 auto;
        padding:0px;
      `)}  
      .mobileAppsTitle {
        position: relative;
        padding: 35px 0 0;
        ${md(`
          padding: 23px 0 0;
        `)}  
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0px;
          right:40px;
          height: 3px;
          background: ${primaryColor};
          ${md(`
              top: 0;
              width:120px;
          `)}  
        }
        h2 {
          margin-bottom: 0px;
          ${md(`
            margin-bottom: 20px;
        `)} 
        }
      }
    }
    .mobileAppsContentRight {
      flex: 0 1 665px;
      padding-left: 25px;
      ${xl(`
           padding-left: 20px;
           flex: 0 1 620px;
      `)} 
      ${lg(`
           padding-left: 15px;
           flex: 0 1 500px;
      `)} 
       ${md(`
            padding:0px;
            flex: 1 1 auto;
        `)} 
      .mobileAppsDetails {
        position: relative;
        padding: 35px 0 0;
        ${md(`
            padding:0px;
        `)} 
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0px;
          width: 100%;
          height: 3px;
          background: ${primaryColor};
          ${md(`
            display:none;
        `)} 
        }
      }
    }
  }
  .fihmainscreen {
    .video-section {
    width: 100%;
    position: relative; 
    video{
      width: 100%;
      height: auto;
    }
    }
  }
`
