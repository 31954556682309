import React from 'react'
import { ProcessinginformationsecStyles } from './Processinginformationsec.styles'
import Image from 'components/ImgOptimized'
import Videoplayer from 'components/Videoplayer/Videoplayer'
import Casequotesec from 'components/Casequotesec/Casequotesec'

function Processinginformationsec(props) {
  const { ProcessinginformationProps } = props.Processinginformationdata
  const caseQuoteProp = ProcessinginformationProps.caseQuoteProps

  const {
    data: {
      processingInfo: {
        childImageSharp: { fluid: processingInfo },
      },
    },
  } = props
  return (
    <ProcessinginformationsecStyles className="Processinginformationsec">
      <div className="container">
        <div className="mobileAppsContent">
          <div className="mobileAppsContentLeft">
            <div className="mobileAppsTitle">
              <h2>{ProcessinginformationProps.subTitle}</h2>
            </div>
          </div>
          <div className="mobileAppsContentRight">
            <div className="mobileAppsDetails">
              <p>{ProcessinginformationProps.para}</p>
            </div>
          </div>
        </div>
        <div className="fihmainscreen">
          {ProcessinginformationProps.videoURL ||
          ProcessinginformationProps.animationvideoURL ? (
            <div className="video-section">
              {ProcessinginformationProps.videoURL ? (
                <Videoplayer videoURL={ProcessinginformationProps.videoURL} />
              ) : (
                <video autoPlay muted loop playsInline>
                  <source
                    src={ProcessinginformationProps.animationvideoURL}
                    type="video/mp4"
                  />
                </video>
              )}
            </div>
          ) : (
            <Image
              fluid={processingInfo}
              lazyload={{
                enabled: true,
                once: true,
                offset: 1000,
                heigth: 680,
              }}
            />
          )}
        </div>
      </div>
      {caseQuoteProp && <Casequotesec {...caseQuoteProp} />}
    </ProcessinginformationsecStyles>
  )
}

export default Processinginformationsec
