import React from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { iResultProps } from 'components/Props/iresult'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import { xl, lg, md } from 'config/variables'
import Casebanner from 'components/Casebanner/Casebanner'
import Caseintroduction from 'components/Caseintroduction/Caseintroduction'
import Fleetarchitecture from 'components/Fleetarchitecture/Fleetarchitecture'
import Caseresultsec from 'components/Caseresultsec/Caseresultsec'
import Casecontentfullwimg from 'components/Casecontentfullwimg/Casecontentfullwimg'
import Keybenefitssec from 'components/Keybenefitssec/Keybenefitssec'
import Resultwithimagessec from 'components/Resultwithimagessec/Resultwithimagessec'
import Treemappingfullimgsec from 'components/Treemappingfullimgsec/Treemappingfullimgsec'
import Processinginformationsec from 'components/Processinginformationsec/Processinginformationsec'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'

export const IResultpage = styled.div`
  .casebanner {
    .container {
      .img-block {
        .banner-img {
          .banner-bg {
            background-color: #dfdcd4;
          }
        }
      }
    }
  }
  .fleetarchitectureSec {
    padding-bottom: 0px;
    margin-bottom: 100px;
    position: relative;
      ${xl(`
      margin-bottom: 80px;
    `)}
    ${lg(`
       margin-bottom: 60px;
    `)}
    ${md(`
       margin-bottom: 40px;
    `)}
    &::after {
      display: none;
    }
  }
  .Caseresultsec  {
    .video-section {
      margin-bottom:48px;
      ${lg(`
        margin-bottom: 40px;
      `)}
      ${md(`
        margin-bottom: 30px;
      `)}
    }
  }
  .Casecontentfullwimg {
    margin-bottom:74px;
    ${xl(`
      margin-bottom:70px;
    `)}
    ${lg(`
       margin-bottom:50px;
    `)}
    ${md(`
       margin-bottom: 30px;
    `)}
  }
  .resultWithImagesSec {
    .row {
      .img-col {
        .rightImagesScreenList {
          padding-bottom:40px;
          &:last-child {
            padding-bottom:0px;
            }
            ${lg(`
              padding-bottom:30px;
            `)}
            ${md(`
                padding-bottom:20px;
            `)}
        }
      }
    }
  }
  .Processinginformationsec  {
     margin-bottom:126px;
      ${xl(`
        margin-bottom:90px;
      `)}
      ${lg(`
        margin-bottom:60px;
      `)}
      ${md(`
          margin-bottom:40px;
      `)}
  }
`
const IResult = props => {
  const { data } = props
  const { location } = props

  const itembottom = props.data.utilizingDiagram.childImageSharp.fluid

  let RightScreenList = [
    props.data.dashboardScreen.childImageSharp.fluid,
    props.data.manageScreen.childImageSharp.fluid,
    props.data.fileUploadScreen.childImageSharp.fluid,
    props.data.enrollmentScreen.childImageSharp.fluid,
  ]
  let leftScreenList = [
    props.data.progressScreen.childImageSharp.fluid,
    props.data.progressDetailsScreen.childImageSharp.fluid,
    props.data.dataScreen.childImageSharp.fluid,
  ]

  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  return (
    <Layout mainClass="case-studie-pages" location={location}>
      <SEO
        title="iResult"
        description=""
        keywords={[`simform`]}
        meta={[
          {
            name: 'ROBOTS',
            content: 'NOINDEX, NOFOLLOW',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <IResultpage>
        <Casebanner
          bannerdata={iResultProps}
          {...props}
          showFullimages={true}
        />
        <Caseintroduction
          caseintrodata={iResultProps}
          {...props}
          showFullimages={true}
        />
        <div className="lazy-load-div">
          <Fleetarchitecture Fleetarchitecturedata={iResultProps} {...props} />
          <Caseresultsec
            caseresultsecdata={iResultProps}
            {...props}
            showImages={false}
          />
        </div>
        {showContent && (
          <>
            <Casecontentfullwimg
              casecontentfullwdata={iResultProps.casecontentfullwbottomProps}
              fluid={itembottom}
            />
            <Keybenefitssec KeyBenefitsdata={iResultProps} {...props} />
            <Resultwithimagessec
              resultwithimagedata={iResultProps}
              {...props}
              leftScreenList={leftScreenList}
              rightScreenList={RightScreenList}
            />
            <Treemappingfullimgsec
              treemappingFullImgData={iResultProps}
              {...props}
              showFullimages
            />
            <Processinginformationsec
              Processinginformationdata={iResultProps}
              {...props}
            />
            <Relatedcaselist relatedcaselistdata={iResultProps} {...props} />
            <Casecontactcardinfo Casecontactcardinfodata={iResultProps} />
          </>
        )}
      </IResultpage>
    </Layout>
  )
}

export default IResult

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/i-result-banner@2x.jpg/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    businessFullImage: file(
      relativePath: { regex: "/iresult-report-card@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ArchitectureImages: file(
      relativePath: { regex: "/fleet-architecture-diagram@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen1: file(relativePath: { regex: "/swifts1@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen2: file(relativePath: { regex: "/swifts2@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen3: file(relativePath: { regex: "/swifts3@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen4: file(relativePath: { regex: "/swifts4@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen5: file(relativePath: { regex: "/swifts5@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen6: file(relativePath: { regex: "/swifts6@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen7: file(relativePath: { regex: "/swifts7@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen8: file(relativePath: { regex: "/swifts8@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen9: file(relativePath: { regex: "/swifts9@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseFullimages: file(
      relativePath: { regex: "/fih-related-case-studies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    KeybenefitsThumb: file(relativePath: { regex: "/benefit-thumb@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 242) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    utilizingDiagram: file(
      relativePath: { regex: "/scalable-daigram@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1116) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dashboardScreen: file(
      relativePath: { regex: "/iresult-dashboard-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 540) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    manageScreen: file(
      relativePath: { regex: "/iresult-manage-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 540) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    fileUploadScreen: file(
      relativePath: { regex: "/iresult-file-upload-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 540) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    enrollmentScreen: file(
      relativePath: { regex: "/iresult-enrollment-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 540) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    progressScreen: file(
      relativePath: { regex: "/iresult-progress-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 540) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    progressDetailsScreen: file(
      relativePath: { regex: "/iresult-progressdetails-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 540) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dataScreen: file(relativePath: { regex: "/iresult-data-screen@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 540) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    treemappingthumb: file(
      relativePath: { regex: "/iresult-older-system-graphs@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    processingInfo: file(
      relativePath: { regex: "/processing-information@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1166) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(
      relativePath: { regex: "/ihealth-casestudie-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    singlecase2: file(
      relativePath: { regex: "/tryg-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
