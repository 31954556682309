import React from 'react'
import { images } from 'config/images'

export const iResultProps = {
  bannerProps: {
    heading: 'iResult',
    bannerPara:
      'Bringing the power of Big Data and data-driven decision making to education using specialized BI & Analytics platform',
    BannerAlt: 'iResult Case Study',
  },
  introducrtionContent: {
    maintile: 'Introduction',
    mainpara: (
      <>
        While edu-tech companies have dramatically improved learning in the
        classroom, District Administrators, Principals and the Board Leadership
        lack solutions that can give them a single point of access to the
        multiple systems they already use. Leaders in education do not have
        access to real-time data from all of the systems they use; and they lack
        the tools to make sense of the complex information gathered from 50 plus
        systems found in an average school district.
        <br /> <br /> The Big Data solution developed by iResult brings out the
        potential of education management systems that help a school to
        determine a range of indicators about teacher, student, and school
        resource’s performance.
      </>
    ),
  },
  Fleetarchitectureprops: {
    heading: 'Shape of our engagement',
    LeftDetailsPara: (
      <>
        {' '}
        When iResult reached out to us, they already had a solution deployed to
        over 600 schools. They wanted to try our team extension model managed by
        with their CTO and COO. After successfully completing the initial pilot
        project, iResult team wanted us to take over the complete overhaul of
        their existing platform to a newer, more powerful, feature rich BI
        platform.
      </>
    ),
    RightDetailsPara: (
      <>
        The older version of the platform was developed by Cybage Software (a
        10,000+ employees outsourcing company) which we had to continue to
        maintain and support until a new system was deployed. iResult didn’t
        just want to buy their way to a better solution. They wanted to change
        the way they work and upskill their own teams along the way.
      </>
    ),
  },
  casereSultsecProps: {
    heading: 'Result',
    maincontent: (
      <>
        iResult had an ambitious mission; they needed to bring their next
        generation platform to the market within 12 months. This required
        redesigning their entire platform and bringing it to a newer cloud based
        big data stack.
        <br /> <br /> In an ever-increasing collection of disconnected systems,
        databases, spreadsheets, and applications, iResult has been successful
        in transforming the way schools deliver real student results. Being an
        intuitive platform, iResult is able to bring together all the
        information at one place to help schools make faster, better-informed
        decisions that support student results.
      </>
    ),
    imgURL2x: images.iResultEngagementScreen2x,
    imgURL: images.iResultEngagementScreen,
  },
  casecontentfullwbottomProps: {
    heading: 'Scalable system architecture',
    para: (
      <>
        iResult’s BI platform is being used daily by over 2000 schools,
        processing millions of students’ and thousands of teacher’s data every
        hour. The data points gathered and their priorities differ from school
        to school. The average school uses at least 5+ systems to gather all of
        this data and the average district has over 50 different systems. <br />{' '}
        <br /> The older SQL based system was taking minutes and sometimes hours
        to process all this data. Naturally, this was not a scalable solution.
        Being an AWS Advanced Partner, we used our expertise in AWS architecture
        to build out a scalable ETL architecture using AWS Glue ETL over Apache
        Spark to bring all the data together in one data lake.
      </>
    ),
    imgCaption: 'ETL process to analyse, process and present the data',
    bottomPara: (
      <>
        The company developed a database architecture to enable a cloud-based
        data warehouse with an open API for easier integration with number of
        systems. We have been able to use the data warehouse infrastructure to
        handle Big Data more efficiently. <br /> <br />
        Microservices based architecture triggers ETL jobs. Subsequently, Apache
        Spark’s clusters process data with lightning-fast speed for insights
        generation. This architecture has allowed whole data sets to be
        processed according to the analytical functions being performed, rather
        than by volume. This has made the processing much more logical and also
        reduced the time and effort taken to run analytics.
      </>
    ),
  },
  KeyBenefitsprops: {
    title: 'Few of the benefits of this architecture',
    BenefitsList: [
      'Rapid ETL processing – 30 minutes vs. 30 hours',
      'Cost-effective scalability',
      'Automated numerous test scripts',
      'Faster data processing by 10x',
      'Cost cutting by 70%',
      'Better scalability and flexibility',
    ],
    ButtonName: 'Get your goals done Now',
    ButtonLink: '/contact/',
  },
  resultwithimageProps: {
    heading: 'Analytics solution for schools',
    para: (
      <>
        Schools track data from exam grades, school program participation,
        absences, behavioural referrals, academic achievements, graduation
        requirements, behaviour, violations, teacher retention, to finance, and
        administration of a school. From district supervisors, to principals, to
        administrators, to teachers, all of these different users need different
        analytics and insights. After the data has been processed, we have to
        make sure the data is actionable. <br /> <br /> We helped develop an
        analytics app suite that makes it possible for anyone in a school
        district to operate on real, actionable information from a single source
        of truth by creating powerful visualizations, dashboards, reports, and
        alerts.
      </>
    ),
  },
  treemappingFullImgProps: {
    title: 'Improvement over older system',
    bottomTitle: 'Architecture',
    bottomContent: <>loosely coupled architecture with microservices</>,
    maincontent:
      'In the old system, users could not perform dynamic queries or advanced filters over reports and data to generate more powerful analytics. The data was outdated and static. In the newer version, we implemented dynamic report generation and generating reports using SQL queries. This obviously gives users much more control and power over advanced analytics. To make the apps perform better, cloud and database side caching with pre-calculations processing helps reduce runtime overheads.',

    bottompara:
      'Microservices architecture on backend and component driven architecture on the front-end along with client side caching made the app suite snappy and responsive. Users can also get insights into different trends over their data. They can also create alerts or scheduled reports/infographics to be sent via email, Slack, SMS, etc over a duration of time or when critical triggers or trends are spotted.',
  },
  ProcessinginformationProps: {
    subTitle: (
      <>
        Alexa, how many students’ attendance declined by 15% in the last quarter
        in Kissimmee district?
      </>
    ),
    para: (
      <>
        iResult’s target users are normal typical folks who would rather not
        spend their days in front of computer or mobile screens. So to make sure
        they get the maximum benefits of the platform, we utilized the power of
        Amazon’s Alexa. <br /> <br /> The Alexa app allows users to perform
        complex queries over different data points using voice only. So that
        when users want a quick answer on some data they can directly use Alexa
        to get what they need. <br /> <br />
        Users can also ask Alexa to remind them when certain parameters in data
        points happen. They can also ask Alexa to send reports via email.
      </>
    ),
    animationvideoURL: images.iresultAlexaVideo,
  },
  relatedcaselistProps: {
    heading: 'Related Case Studies',
    caselistProps: [
      {
        title: 'iHealth',
        para:
          'Clinically validated health monitoring and fitness coaching solution',
        className: 'soft-pink-two',
        link: '/ihealth/',
      },
      {
        title: 'Tryg',
        para:
          'Scandinavia`s second largest general insurer brings transparency to insurance claims with telematics',
        className: 'light-peach-five',
        link: '/tryg/',
      },
    ],
  },
  CasecontactcardinfoProps: {
    CTACard: {
      title: (
        <>
          Speak to our experts to unlock the value Mobility, IoT, and Data
          Insights!
        </>
      ),
      buttonName: 'Contact Us',
      buttonLink: '/contact/',
    },
  },
}
