import React from 'react'
import { KeybenefitssecStyles } from './Keybenefitssec.styles'
import Image from 'components/ImgOptimized'
import Button from 'components/Button/Button'

function Keybenefitssec(props) {
  const { KeyBenefitsprops } = props.KeyBenefitsdata
  const {
    data: {
      KeybenefitsThumb: {
        childImageSharp: { fluid },
      },
    },
  } = props
  return (
    <KeybenefitssecStyles className="KeybenefitsMainsec">
      <div className="container">
        <div className="KeybenefitsSec">
          <div className="KeybenefitsSec-bg"></div>
          <div className="KeybenefitsSec-blk">
            <div className="KeybenefitsThumb">
              <figure>
                <Image
                  fluid={fluid}
                  lazyload={{
                    enabled: true,
                    once: true,
                    offset: 1000,
                    heigth: 224,
                  }}
                />
              </figure>
            </div>
            <div className="KeybenefitsDetails">
              <h4>{KeyBenefitsprops.title}</h4>
              <ul className="benefitsList">
                {KeyBenefitsprops.BenefitsList.map((KeyBenefitsprop, i) => {
                  return <li key={i}>{KeyBenefitsprop}</li>
                })}
              </ul>
              {KeyBenefitsprops.ButtonName ? (
                <Button
                  buttonName={KeyBenefitsprops.ButtonName}
                  buttonLink={KeyBenefitsprops.ButtonLink}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </KeybenefitssecStyles>
  )
}

export default Keybenefitssec
