import React from 'react'

import { FleetarchitectureStyles } from './Fleetarchitecture.styles'
import Image from 'components/ImgOptimized'
import Casequotesec from 'components/Casequotesec/Casequotesec'

function Fleetarchitecture(props) {
  const {
    Fleetarchitecturedata: { Fleetarchitectureprops },
    showFullimages,
  } = props
  const caseQuoteProp = Fleetarchitectureprops.caseQuoteProps
  const {
    data: {
      ArchitectureImages: {
        childImageSharp: { fluid: ArchitectureImages },
      },
    },
  } = props
  return (
    <FleetarchitectureStyles className="fleetarchitectureSec">
      <div className="container">
        <div className="fleetArchitectureDetails">
          <div className="fleetArchitectureHead">
            <h2>{Fleetarchitectureprops.heading}</h2>
          </div>
          {Fleetarchitectureprops.RightDetailsPara ||
          Fleetarchitectureprops.FleetarchitectureList ||
          Fleetarchitectureprops.LeftDetailsPara ? (
            <div className="fleetArchitectureRow">
              {Fleetarchitectureprops.LeftDetailsPara ? (
                <div className="ArchitectureDetailsLeft">
                  <p>{Fleetarchitectureprops.LeftDetailsPara}</p>
                </div>
              ) : (
                ''
              )}
              {Fleetarchitectureprops.RightDetailsPara ||
              Fleetarchitectureprops.FleetarchitectureList ? (
                <div className="ArchitectureDetailsRight">
                  {Fleetarchitectureprops.RightDetailsPara ? (
                    <p>{Fleetarchitectureprops.RightDetailsPara}</p>
                  ) : (
                    ''
                  )}
                  {Fleetarchitectureprops.FleetarchitectureList ? (
                    <ol className="architectureList">
                      {Fleetarchitectureprops.FleetarchitectureList.map(
                        (architectureList, i) => {
                          return <li key={i}>{architectureList}</li>
                        }
                      )}
                    </ol>
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
          {showFullimages && (
            <div className="fleetArchitectureImage">
              <figure>
                <Image
                  fluid={ArchitectureImages}
                  lazyload={{
                    enabled: true,
                    once: true,
                    offset: 1000,
                    heigth: 694,
                  }}
                />
                <div className="img-caption">
                  {Fleetarchitectureprops.mainImgcaption}
                </div>
              </figure>
            </div>
          )}
          {Fleetarchitectureprops.LeftBottomDetailsPara ||
          Fleetarchitectureprops.RightBottomDetailsPara ||
          Fleetarchitectureprops.FleetarchitectureBottomList ? (
            <div className="fleetArchitectureRow">
              {Fleetarchitectureprops.LeftBottomDetailsPara ? (
                <div className="ArchitectureDetailsLeft">
                  <p>{Fleetarchitectureprops.LeftBottomDetailsPara}</p>
                </div>
              ) : (
                ''
              )}
              {Fleetarchitectureprops.RightBottomDetailsPara ||
              Fleetarchitectureprops.FleetarchitectureBottomList ? (
                <div className="ArchitectureDetailsRight">
                  {Fleetarchitectureprops.RightBottomDetailsPara ? (
                    <p>{Fleetarchitectureprops.RightBottomDetailsPara}</p>
                  ) : (
                    ''
                  )}
                  {Fleetarchitectureprops.FleetarchitectureBottomList ? (
                    <ol className="architectureList">
                      {Fleetarchitectureprops.FleetarchitectureBottomList.map(
                        (architectureBottomList, i) => {
                          return <li key={i}>{architectureBottomList}</li>
                        }
                      )}
                    </ol>
                  ) : (
                    ''
                  )}
                  {Fleetarchitectureprops.RightBottomListPara ? (
                    <p>{Fleetarchitectureprops.RightBottomListPara}</p>
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      {caseQuoteProp && <Casequotesec {...caseQuoteProp} />}
    </FleetarchitectureStyles>
  )
}

export default Fleetarchitecture
