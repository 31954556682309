import styled from 'styled-components'
import { metaFont, bodyFont, lg, xl, md } from 'config/variables'
export const FleetarchitectureStyles = styled.div`
    padding-bottom: 100px;
    margin-bottom: 128px;
    position: relative;
     ${xl(` 
      margin-bottom: 100px;
    `)}
    ${lg(` 
      padding-bottom: 80px; 
       margin-bottom: 90px;
    `)}
    ${md(` 
      padding-bottom: 40px; 
       margin-bottom: 40px;
    `)}
  &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 790px;
      bottom: 0;
      left: 0;
      background: linear-gradient(to bottom, rgba(252, 253, 255, 0), #f8f9ff);
      z-index: -1;
    }
  .fleetArchitectureRow {
    display: flex;
    justify-content: space-between;
    ${lg(`
        flex-direction: column;
      `)}
    .ArchitectureDetailsLeft {
      flex: 1 1 50%;
      padding: 0 40px 0 0;
      ${xl(`
       padding: 0 25px 0 0;
      `)}
      ${lg(`
       padding: 0 0px 0 0;
      `)}
    }
    .ArchitectureDetailsRight {
      flex: 1 1 50%;
      padding: 0 0 0 40px;
      ${xl(`
        padding: 0 0 0 25px;
      `)}
      ${lg(`
        padding: 0px 0 0 0;
      `)}
    }
    .architectureList {
      padding: 0px;
      margin: 0px;
      counter-reset: item;
      list-style-type: none;
      ${lg(`
        padding: 40px 0 0 0;
      `)}
      ${md(`
        padding: 0px 0 0 0;
      `)}
      li {
        display: block;
        font-size: 24px;
        line-height: 34px;
        font-weight: bold;
        font-family: ${metaFont};
        padding: 0 0 20px 68px;
        margin-bottom: 20px;
        position: relative;
        border-bottom: solid 2px #d8d8d8;
        ${md(`
            padding: 0 0 16px 40px;
            font-size:16px;
            line-height:26px;
            margin-bottom: 16px;
        `)}
        &:last-child {
          border-bottom: none;
          padding-bottom:0px;
        }
        &:before {
          content: counter(item, decimal-leading-zero) ' ';
          counter-increment: item;
          left: 0;
          top: 0px;
          font-size: 22px;
          font-weight: 500;
          color: #85c26a;
          font-family: ${bodyFont};
          position: absolute;
          ${md(`
            font-size:18px;
        `)}
        }
      }
    }
  }
  .fleetArchitectureImage {
    margin-bottom: 80px;
    ${xl(`
       margin-bottom:50px;
    `)}
    ${lg(`
        margin-bottom:50px;
    `)}
    ${md(`
        margin-bottom:40px;
    `)}
  }
`
